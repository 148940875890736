module.exports = [{
      plugin: require('/Users/pate/projects/freelance/incorporebcn/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"incorpore-barcelona","short_name":"incorpore bcn","start_url":"/","background_color":"#49B79F","theme_color":"#49B79F","display":"minimal-ui","icon":"src/images/incorpore_perfil_03.png"},
    },{
      plugin: require('/Users/pate/projects/freelance/incorporebcn/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBNRGFD","includeInDevelopment":false,"gtmAuth":"2PPu1b5fOgSKV8rYfVeDCQ","gtmPreview":"env-2"},
    },{
      plugin: require('/Users/pate/projects/freelance/incorporebcn/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
